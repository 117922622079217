import React, { useState, useEffect } from 'react';
import { Badge, Typography, Carousel, Image, Button } from 'antd';
import { AlertFilled, DashboardFilled, SlidersFilled } from '@ant-design/icons';
import tickAsset from '../../assets/tick.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/ban.jpg';
import anh1 from '../../assets/bg123.jpeg';
import anh2 from '../../assets/bg11.jpg';
import anh3 from '../../assets/anh3.jpeg';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
const arrayText = [
  '097***4 đã rút 35.000.000 VND',
  '033***8 đã rút 30.000.000 VND',
  '087***3 đã rút 45.000.000 VND',
  '033***1 đã rút 50.000.000 VND',
  '035***5 đã được duyệt hồ sơ',
  '033***5 đã rút 45.000.000 VND',
  '035***2 đã được duyệt hồ sơ',
  '033***1 đã rút 30.000.000 VND',
  '087***9 đã rút 45.000.000 VND',
  '035***8 đã được duyệt hồ sơ',
];

export default function Home() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1)
        setCurrentIndex((prev) => prev + 1);
      else setCurrentIndex((prev) => 0);
    }, 1800);
    return () => clearInterval(interval);
  });
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content">
        <div className="user-info">
          <Typography.Text className="text-head">Xin chào, </Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>

        {/*<div className="contact-info" style={{ textAlign: 'left', paddingLeft: '220px' }}>*/}
        {/*  <div>Hotline CSKH</div>*/}
        {/*  <div>1900292926</div>*/}
        {/*</div>*/}

        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push('/notifications')}
        >
          <Notification />
        </motion.div>
      </div>

      <div className="noti-text-container">
        <Typography.Text className="noti-text">
          {arrayText[currentIndex]}
        </Typography.Text>
      </div>
      <div>
        <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
          <div>
            <img style={{ width: '100%', borderRadius: 10 }} src={anh1} />
          </div>
          {/* <div>
            <div className="carousel slider5">
              <div className="sl-text-container"></div>
            </div>
          </div>
          <div>
            <div className="carousel slider6">
              <div className="sl-text-container"></div>
            </div>
          </div> */}
        </Carousel>
      </div>
      <Button
        className="choose-btn"
        onClick={() => {
          history.push('/vay');
        }}
      >
        <Typography.Text
          style={{ color: '#fff', fontWeight: 'bold', fontSize: 20 }}
        >
          Đăng ký khoản vay
        </Typography.Text>
      </Button>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
          paddingTop: 10,
        }}
      >
        <Tab
          title={
            <span style={{ color: '#0974f0' }}>
              Hỗ trợ trên toàn lãnh thổ
            </span>
          }
          icon={<AlertFilled style={{ fontSize: 20, color: '#ff7323' }} />}
        />
        <Tab
          title={
            <span style={{ color: '#0974f0' }}>
              Thủ tục đơn giản - An toàn - Bảo mật
            </span>
          }
          icon={<AlertFilled style={{ fontSize: 20, color: '#ff7323' }} />}
        />
        <Tab
          title={
            <span style={{ color: '#0974f0' }}>
              Hạn mức vay lên đến 300 triệu VND
            </span>
          }
          icon={<DashboardFilled style={{ fontSize: 20, color: '#008f50' }} />}
        />
        <Tab
          title={
            <span style={{ color: '#0974f0' }}>
              Không phí hồ sơ, Không thẩm định người thân
            </span>
          }
          icon={<SlidersFilled style={{ fontSize: 20, color: '#0974f0' }} />}
        />
      </div>
      <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
        <div>
          <img style={{ width: '100%', borderRadius: 10 }} src={anh2} />
        </div>
        {/* <div >
          <div className="carousel slider1">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider4">
            <div className="sl-text-container"></div>
          </div>
        </div> */}
      </Carousel>
      <div
        style={{
          paddingTop: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image src={tickAsset} preview={false} style={{ maxWidth: 100 }} />
        {/* <Typography.Text
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#555',
            textAlign: 'center',
          }}
        >
          <br /> Tầng 3, Tòa nhà VIT, 519 Kim Mã, Quận Ba Đình, Hà Nội, Việt Nam
          <br /> Bản quyền thuộc về
          <br /> MCREDIT
        </Typography.Text> */}
      </div>
    </motion.div>
  );
}
const Tab = ({ title, onClick, icon }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid #006997',
      }}
      onClick={onClick}
      style={{
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '7px 15px',
        border: '1px solid #0974f0',
        borderRadius: 5,
      }}
    >
      <Typography.Text
        style={{ flex: 1, fontSize: 16, color: '#006997', fontWeight: 500 }}
      >
        {title}
      </Typography.Text>
      {icon}
    </motion.div>
  );
};
