import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../api/';
import moment from 'moment';

import _img from "../../assets/copy.png"
import { Typography, Empty, Spin, Image } from 'antd';
import { motion } from 'framer-motion';
import {
  LeftOutlined,
  LoadingOutlined,
  CheckOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';
import './bankinfo.scss';
import { useSelector } from 'react-redux';
// import _img from '../../assets/success.jpg';


async function copyToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;

    // Move the textarea outside the viewport to make it invisible
    textarea.style.position = 'absolute';
    textarea.style.left = '-99999999px';

    document.body.prepend(textarea);

    // highlight the content of the textarea element
    textarea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.log(err);
    } finally {
      textarea.remove();
    }
  }
}
export default function () {
  const history = useHistory();
  const { bankDetails } = useSelector((state) => state._content);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState({});
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get('/contracts');
      if (data.data.length > 0) setContract(data.data[0]);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get('/payments');
      setRequests([...data.data]);
      setLoading(false);
    })();
  }, []);
  return (

      <div style={{backgroundColor:"#fafafa"}}>


            <div className="head-container">
              <div
                className="head"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <motion.div whileTap={{ scale: 0.95, x: -10 }}>
                  <LeftOutlined
                    className="left-icon"
                    onClick={() => history.replace('/wallet')}
                  />
                </motion.div>
                <Typography.Text strong style={{ color: '#ffffff', fontSize: 17 }}>
                  Thông tin Ngân hàng trả nợ
                </Typography.Text>
                <div />
              </div>
            </div>

           <div style={{ padding: 10 , paddingTop:30, paddingBottom:20}}>
            <div style={{backgroundColor: "#fff" , border: 2 , borderRadius:10, padding: 5}}>
              <div className="item-text">
                <h2><span> Tài khoản ngân hàng </span></h2>
              </div>
              <br/>


              <div style={{paddingTop: 20}}>
                <div style={{ display: 'flex' ,justifyContent: "space-between", width: '100%', paddingBottom:8}}>
                  <Typography.Text className="content-text">
                    Tên ngân hàng :
                  </Typography.Text>
                  <Image style={{width: 25, cursor: 'pointer'}} src={_img} preview={false} onClick={() => copyToClipboard(bankDetails?.bankName).then(() => {
                    alert("Text copied to clipboard successfully")})} />
                </div>
                <Typography.Text className="content-body">
                  {bankDetails?.bankName}

                </Typography.Text>
                <div style={{ borderTop: '3px dotted #1FA6A0FF' }}>
                </div>
              </div>

              <div style={{paddingTop: 20}}>
                <div style={{ display: 'flex' ,justifyContent: "space-between", width: '100%', paddingBottom:8}}>
                  <Typography.Text className="content-text">
                    STK ngân hàng :
                  </Typography.Text>
                  <Image style={{width: 25, cursor: 'pointer'}} src={_img} preview={false} onClick={() => copyToClipboard(bankDetails?.bankSTK) .then(() => {
                    alert("Text copied to clipboard successfully")})} />
                </div>
                <Typography.Text className="content-body">
                  {bankDetails?.bankSTK}
                </Typography.Text>
                <div style={{ borderTop: '3px dotted #1FA6A0FF' }}>
                </div>
              </div>

              <div style={{paddingTop: 20}}>
                <div style={{ display: 'flex' ,justifyContent: "space-between", width: '100%', paddingBottom:8}}>
                  <Typography.Text className="content-text">
                    Chủ tài khoản :
                  </Typography.Text>
                  <Image style={{width: 25, cursor: 'pointer'}} src={_img} preview={false} onClick={() => copyToClipboard(bankDetails?.accountOwner) .then(() => {
                    alert("Text copied to clipboard successfully")})} />
                </div>
                <Typography.Text className="content-body">
                  {bankDetails?.accountOwner}
                </Typography.Text>
                <div style={{ borderTop: '3px dotted #1FA6A0FF' }}>
                </div>
              </div>

              <div style={{paddingTop: 20}}>
                <div style={{ display: 'flex' ,justifyContent: "space-between", width: '100%', paddingBottom:8}}>
                  <Typography.Text className="content-text">
                    Người đại diện :
                  </Typography.Text>
                  <Image style={{width: 25, cursor: 'pointer'}} src={_img} preview={false} onClick={() => copyToClipboard(bankDetails?.mobilePeople) .then(() => {
                    alert("Text copied to clipboard successfully");
                  })} />
                </div>
                <Typography.Text className="content-body">
                  {bankDetails?.mobilePeople}
                </Typography.Text>
                <div style={{ borderTop: '3px dotted #1FA6A0FF' }}>
                </div>
              </div>

              <div style={{paddingTop: 20}}>
                <div style={{ display: 'flex' ,justifyContent: "space-between", width: '100%', paddingBottom:8}}>
                  <Typography.Text className="content-text">
                    Nội dung :
                  </Typography.Text>
                  <Image style={{width: 25 , cursor: 'pointer'}} src={_img} preview={false} onClick={() => copyToClipboard(bankDetails?.Content) .then(() => {
                    alert("Text copied to clipboard successfully")})} />
                </div>
                <Typography.Text className="content-body">
                  {bankDetails?.Content ? bankDetails?.Content : ""}
                </Typography.Text>
                <div style={{ borderTop: '3px dotted #1FA6A0FF' }}>
                </div>
              </div>



            </div>

             {/*{loading ? (*/}
             {/*   <div className="loading">*/}
             {/*     <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />*/}
             {/*     <Typography.Text>Đang tải dữ liệu...</Typography.Text>*/}
             {/*   </div>*/}
             {/* ) : (*/}
             {/*   // <>*/}
             {/*   //   {requests.length == 0 && <Empty description="Chưa có dữ liệu" />}*/}
             {/*   //   {requests.map((item, index) => (*/}
             {/*   //     <Item item={item} key={index} />*/}
             {/*   //   ))}*/}
             {/*   // </>*/}
             {/*  */}
             {/*  */}
             {/*   <br/>*/}
             {/* )}*/}
        </div>
    {/*</motion.div>*/}
</div>
  );
}

// const Item = ({ item }) => {
//   return (
//     <div className="item-box" style={{ margin: 5 }}>
//       <div>
//         <Typography.Text className="item-text">
//           {moment(item.created_at).format('hh:mm A DD/MM/YYYY')}
//         </Typography.Text>
//         <br />
//         <Typography.Text className="item-text">
//           {item.description}
//         </Typography.Text>
//         <br />
//         <Typography.Text className="item-text">
//           {item.status ? '+ ' : '- '}
//           {item.amount.toLocaleString()} đ
//         </Typography.Text>
//       </div>
//       <div>
//         {item.status ? (
//           <CheckOutlined className="check-icon" />
//         ) : (
//           <ExclamationOutlined className="exclamation-icon" />
//         )}
//       </div>
//     </div>
//   );
// };
